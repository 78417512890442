import { render } from "./notice.vue?vue&type=template&id=17c5ee34&scoped=true"
import script from "./notice.vue?vue&type=script&lang=js"
export * from "./notice.vue?vue&type=script&lang=js"

import "./notice.vue?vue&type=style&index=0&id=17c5ee34&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-17c5ee34"
/* hot reload */
if (module.hot) {
  script.__hmrId = "17c5ee34"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('17c5ee34', script)) {
    api.reload('17c5ee34', script)
  }
  
  module.hot.accept("./notice.vue?vue&type=template&id=17c5ee34&scoped=true", () => {
    api.rerender('17c5ee34', render)
  })

}

script.__file = "src/views/my/notice/notice.vue"

export default script