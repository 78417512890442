<template>
    <div class="notice">
        <headBack>
            <template v-slot:title>
                <div class='title'>
                    消息中心
                </div>
            </template>
        </headBack>
        <div class='header_cont'></div>
        <div class='notice_content'>
            <div class='notice_list'>
                <div class='list_left'>
                    <img src='https://static.yihu365.cn/img/h5Img/assets/img/my/notice_icon.png' class='notice_icon'/>
                </div>
                <div class='list_right'>
                    <div class='title'>普通消息</div>
                    <div class='time'>04-12</div>
                    <div class='content'>提醒您，您的账户在</div>
                </div>
            </div>
            <div class='notice_list'>
                <div class='list_left'>
                    <img src='https://static.yihu365.cn/img/h5Img/assets/img/my/notice_icon.png' class='notice_icon'/>
                </div>
                <div class='list_right'>
                    <div class='title'>普通消息</div>
                    <div class='time'>04-12</div>
                    <div class='content'>提醒您，您的账户在</div>
                </div>
            </div>
            <div class='notice_list'>
                <div class='list_left'>
                    <img src='https://static.yihu365.cn/img/h5Img/assets/img/my/notice_icon.png' class='notice_icon'/>
                </div>
                <div class='list_right'>
                    <div class='title'>普通消息</div>
                    <div class='time'>04-12</div>
                    <div class='content'>提醒您，您的账户在</div>
                </div>
            </div>
            <div class='notice_list'>
                <div class='list_left'>
                    <img src='https://static.yihu365.cn/img/h5Img/assets/img/my/notice_icon.png' class='notice_icon'/>
                </div>
                <div class='list_right'>
                    <div class='title'>普通消息</div>
                    <div class='time'>04-12</div>
                    <div class='content'>提醒您，您的账户在</div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { onMounted, ref, computed } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Dialog } from 'vant'



export default {
  name: 'notice',
  components: {


  },
  setup () {
    let router = useRouter()
    const store = useStore()
    const route = useRoute()



    return {

    }
  },

}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
    .notice{
        height: 100%;
        background: #fff;
    }
    .notice_content{
        padding:0 30px;
    }
    .notice_list{
        padding:30px 0;
        display: flex;
        justify-content: flex-start;
        border-bottom: 1px solid #EDEDED;
        .title{
            font-size: 30px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #313131;
        }
        .time{
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #919090;
            margin-top:5px;
        }
        .list_left{
            flex-shrink: 0;
        }
        .list_right{
            margin-left:20px;
            width:100%;
            .content{
                margin-top:23px;
                background: #F6F7FA;
                padding:20px 15px;
                font-size: 28px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #313131;
            }
        }
    }
    .notice_icon{
        width: 80px;
        height: 80px;
    }

</style>
